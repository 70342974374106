import React from 'react';
import { format, getHours, getMinutes } from 'date-fns';

import { hasFlag } from 'country-flag-icons';
import { findTournament } from '../common/constants';

import scotlandFlag from '../assets/scotland.svg';
import englandFlag from '../assets/england.svg';
import championsLeagueFlag from '../assets/championsLeagueFlag.svg';
import northIrelandFlag from '../assets/northIreland.svg';
import europaLeagueFlag from '../assets/europaLeagueFlag.svg';
import conferenceLeagueFlag from '../assets/conferenceLeagueFlag.svg';
import friendliesFlag from '../assets/friendliesFlag.svg';
import africaCupOfNationsFlag from '../assets/africaCupOfNationsFlag.svg';
import asianCupFlag from '../assets/asianCup.svg';
import copaSudamericanaFlag from '../assets/copaSudamericanaFlag.svg';
import copaLibertadoresFlag from '../assets/copaLibertadoresFlag.svg';
import worldCupFlag from '../assets/worldCup.svg';
import euroFlag from '../assets/euroFlag.svg';
import olympicsFlag from '../assets/olympicsFlag.svg';
import tvFixtures from '../assets/tvFixtures.svg';
import communityShield from '../assets/communityShield.svg';
import germanSupercup from '../assets/germanSupercup.svg';
import uefaSupercup from '../assets/uefaSupercup.svg';
import copaAmerica from '../assets/copaAmerica.svg';
import championsLeagueAsiaFlag from '../assets/championsLeagueAsiaFlag.svg';

const getDate = (date, withYear = true) => {
	const months = ['ян', 'февр', 'мар', 'апр', 'май', 'юни', 'юли', 'авг', 'септ', 'окт', 'ноем', 'дек'];
	const day = date.getDate();
	const month = ` ${months[date.getMonth()]}`;
	const year = ` ${date.getFullYear()}`;

	return `${day}${month}${withYear ? year : ''}`;
};

export const months = [
	'Януари',
	'Февруари',
	'Март',
	'Април',
	'Май',
	'Юни',
	'Юли',
	'Август',
	'Септември',
	'Октомври',
	'Ноември',
	'Декември'
];

const getMonth = (date) => {
	const month = ` ${months[date.getMonth()]}`;
	
	return month;
};

/**
 * Gets a flag svg of a given country abreviation.
 */
const getCountryFlag = tournamentId => {
	// tv fixtures
	if (tournamentId === 999999 ) {
		return tvFixtures;
	}

	const currentTournament = findTournament(tournamentId);

	const additionalFlags = {
		179: scotlandFlag,
		185: scotlandFlag,
		181: scotlandFlag,
		45: englandFlag,
		46: englandFlag,
		40: englandFlag,
		39: englandFlag,
		41: englandFlag,
		42: englandFlag,
		43: englandFlag,
		48: englandFlag,
		2: championsLeagueFlag,
		3: europaLeagueFlag,
		848: conferenceLeagueFlag,
		10: friendliesFlag,
		667: friendliesFlag,
		6: africaCupOfNationsFlag,
		7: asianCupFlag,
		29: worldCupFlag,
		30: worldCupFlag,
		31: worldCupFlag,
		32: worldCupFlag,
		13: copaLibertadoresFlag,
		11: copaSudamericanaFlag,
		33: worldCupFlag,
		34: worldCupFlag,
		1: worldCupFlag,
		960: euroFlag,
		4: euroFlag,
		528: communityShield,
		529: germanSupercup,
		531: uefaSupercup,
		9: copaAmerica,
		480: olympicsFlag,
		524: olympicsFlag,
		17: championsLeagueAsiaFlag,
		18: asianCupFlag,
		408: northIrelandFlag,
	};

	if (additionalFlags[currentTournament?.tournamentId]) {
		return additionalFlags[currentTournament?.tournamentId];
	}

	if (currentTournament && hasFlag(currentTournament.countryAbreviation)) {
		return `https://purecatamphetamine.github.io/country-flag-icons/3x2/
			${currentTournament.countryAbreviation}.svg`;
	}
};

// TODO - move those to constants
const dateFormat = 'dd-MM-yyyy';
const apiDateFormat = 'yyyy-MM-dd';
const weekDays = ['НД', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
// TODO

const useViewport = () => {
	if (typeof window === 'undefined') {
		return { width: 750 };
	}

	const [width, setWidth] = React.useState(window.innerWidth);

	const handleWindowResize = () => setWidth(window.innerWidth);

	React.useEffect(() => {
		window.addEventListener('resize', handleWindowResize);
		return () => window.removeEventListener('resize', handleWindowResize);
	}, []);

	// Return the width so we can use it in our components
	return { width };
};

const changeUrl = url => {
	window.history.replaceState({}, null, url);
};

const getUrlParameter = (parameter) => {
	if (typeof window !== 'undefined') {
		const params = new URLSearchParams(window.location.search);
		return params.get(parameter);
	}

	return '';
};

const getApiDate = (date) => {
	const [day, month, year] = date.split('-');
	const apiDate = format(new Date(+year, Number(month) - 1, +day), apiDateFormat);

	return apiDate;
};

const isCurrentTimeBetween0000And0005 = () => {
	const now = new Date();
	const hours = getHours(now);
	const minutes = getMinutes(now);

	// Check if the current time is exactly at 0:00 or up to 0:05
	return hours === 0 && minutes >= 0 && minutes <= 5;
};

const shuffleArray = (array) => { 
	for (let i = array.length - 1; i > 0; i--) { 
		const j = Math.floor(Math.random() * (i + 1)); 
		[array[i], array[j]] = [array[j], array[i]]; 
	} 
	return array; 
}; 

export {
	getDate,
	getCountryFlag,
	useViewport,
	changeUrl,
	getUrlParameter,
	weekDays,
	dateFormat,
	getApiDate,
	getMonth,
	isCurrentTimeBetween0000And0005,
	shuffleArray
};