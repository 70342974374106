import React from 'react';
import { Provider } from 'react-redux';
import { createStore as reduxCreateStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { getStandingsMiddleware } from './middlewares/standings.middlewares';
import {
	getEcFixturesMiddleware,
	getFixturesMiddleware,
	getTopFixturesMiddleware,
} from './middlewares/fixtures.middlewares';
import rootReducer from '.';
import { getFixtureDetailsMiddleware, voteForFixtureMiddleware } from './middlewares/fixture-details.middlewares';
import { getTeamMiddleware } from './middlewares/teams.middlewares';
import { getNewsMiddleware } from './middlewares/news.middlewares';
import { loginMiddleware, logoutMiddleware } from './middlewares/auth.middlewares';
import {
	changeUserPasswordMiddleware,
	deleteUserMiddleware,
	getLoggedUserMiddleware,
	getProfileDataMiddleware,
	getUsersGameDataMiddleware,
	registerUserMiddleware,
	takeUserMissionCoinsMiddleware,
	changeUserForgottenPasswordMiddleware,
	sendUserForgottenPasswordEmailMiddleware,
	buyWidgetMiddleware
} from './middlewares/users.middlewares';
import {
	getQuizByIdMiddleware,
	getQuizesMiddleware,
	incrementQuizTriesMiddleware,
	saveQuizAnswersMiddleware,
	getQuizStandingsMiddleware,
} from './middlewares/quizes.middlewares';
import {
	getUserAvatarMiddleware,
	buyAvatarItemMiddleware,
	saveUsedAvatarItemsMiddleware
} from './middlewares/avatar.middlewares';

const initStore = () => {

	const storeEnhancers = typeof window !== 'undefined' ? 
		(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
	const middlewares = [
		getStandingsMiddleware,
		getFixturesMiddleware,
		getTopFixturesMiddleware,
		getFixtureDetailsMiddleware,
		getTeamMiddleware,
		getNewsMiddleware,
		loginMiddleware,
		getUsersGameDataMiddleware,
		registerUserMiddleware,
		getProfileDataMiddleware,
		getLoggedUserMiddleware,
		voteForFixtureMiddleware,
		logoutMiddleware,
		deleteUserMiddleware,
		changeUserPasswordMiddleware,
		getUserAvatarMiddleware,
		saveUsedAvatarItemsMiddleware,
		buyAvatarItemMiddleware,
		buyWidgetMiddleware,
		getQuizesMiddleware,
		getQuizByIdMiddleware,
		getQuizStandingsMiddleware,
		saveQuizAnswersMiddleware,
		getEcFixturesMiddleware,
		incrementQuizTriesMiddleware,
		takeUserMissionCoinsMiddleware,
		changeUserForgottenPasswordMiddleware,
		sendUserForgottenPasswordEmailMiddleware
	];
	const store = reduxCreateStore(rootReducer, storeEnhancers(applyMiddleware(...middlewares, thunk)));
	return store;
};

export const store = initStore();

export default ({ element }) => (
	<Provider store={store}>{element}</Provider>
);