import { toast } from 'react-toastify';
import { findTournament } from '../../common/constants';
import httpProvider from '../../utils/httpProvider';
import { getApiDate } from '../../utils/utils';
import {
	fixturesReady,
	topFixturesReady,
	GET_FIXTURES,
	GET_TOP_FIXTURES,
	stopLoadingFixtures,
	startLoadingFixtures,
	factsReady,
	GET_EC_FIXTURES,
	ecFixturesReady
} from '../actions/fixtures.actions';
import { format } from 'date-fns';

export const createFixtureGroupsByTournamentId = (
	fixturesData
) => {
	return fixturesData.reduce((state, fixture) => {

		const currentTournamentId = fixture.tournamentId;

		if (state[currentTournamentId]) {
			state[currentTournamentId].fixtures.push(fixture);
		} else {
			const currentTournament = findTournament(currentTournamentId);

			if (currentTournament) {
				state[currentTournamentId] = {
					rank: currentTournament.rank,
					tournamentId: currentTournamentId,
					fixtures: [fixture],
					tournamentName: currentTournament.tournamentName,
					country: currentTournament.country,
					tournamentNameBg: currentTournament.tournamentNameBg,
					countryBg: currentTournament.countryBg,
				};
			}
		}

		return {
			...state
		};
	}, {});
};

const getFixturesMiddleware = ({ dispatch, getState }) => next => async action => {
	if(action.type === GET_FIXTURES) {
		dispatch(startLoadingFixtures());

		try {
			const today = format(new Date(), 'yyyy-MM-dd');
			const date = getApiDate(action.payload);

			const response  = await httpProvider.get(`fixtures?date=${date}`);

			const fixtures = createFixtureGroupsByTournamentId(response.data);
			const fixturesState = {
				[`${action.payload}`]: fixtures,

			};
  
			if (fixtures) {
				dispatch(fixturesReady(fixturesState));
			}

			if (date === today && !getState().fixtures.facts) {
				const factsResponse = await httpProvider.get(`predictions/facts?day=${today}`);

				if (fixtures) {
					dispatch(factsReady(factsResponse.data));
				}
			}
		} catch (err) {
			dispatch(fixturesReady({}));
			dispatch(factsReady([]));
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingFixtures());
		}
	} else {
		return next(action);
	}
};

const getTopFixturesMiddleware = ({ dispatch }) => next => async action => {
	if(action.type === GET_TOP_FIXTURES) {
		dispatch(startLoadingFixtures());

		try {
			const date = getApiDate(action.payload);
			const response  = await httpProvider.get(`fixtures/top?date=${date}`);
			const fixtures = response.data;

			fixtures.sort((first, second) => {
				return new Date(first.startTime) - new Date(second.startTime);
			});

			const topFixturesState = {
				topFixtures: fixtures
			};
  
			if (response.data) {
				dispatch(topFixturesReady(topFixturesState));
			}
		} catch (err) {
			dispatch(topFixturesReady({}));
		} finally {
			dispatch(stopLoadingFixtures());
		}
	} else {
		return next(action);
	}
};

const getEcFixturesMiddleware = ({ dispatch }) => next => async action => {
	if(action.type === GET_EC_FIXTURES) {
		dispatch(startLoadingFixtures());

		try {
			const today = format(new Date(), 'dd-MM-yyyy');
			const date = getApiDate(today);
			const response  = await httpProvider.get(`fixtures/ec?date=${date}`);
			const fixtures = response.data;

			const ecFixturesState = {
				ecFixtures: fixtures
			};
  
			if (response.data) {
				dispatch(ecFixturesReady(ecFixturesState));
			}
		} catch (err) {
			dispatch(ecFixturesReady({}));
			toast.error('Нещо се обърка! Моля опитайте по-късно.');
		} finally {
			dispatch(stopLoadingFixtures());
		}
	} else {
		return next(action);
	}
};

export {
	getFixturesMiddleware,
	getTopFixturesMiddleware,
	getEcFixturesMiddleware
};